import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide33/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide33/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide33/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide33/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide33/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      Cómo medir y mejorar la calidad del servicio al cliente{" "}
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      La calidad del servicio está directamente relacionada con la satisfacción
      y la lealtad hacia la empresa. Cuanto mejor perciban tus clientes el
      servicio al cliente y la experiencia general que ofrecen, más probable es
      que vuelvan a visitar tu empresa y la recomienden a otros.{" "}
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Medir las percepciones de tus clientes sobre su experiencia implica
    solicitar y analizar sus comentarios en varios puntos de su recorrido.
    <br />
    <br />
    Con toda la información de los clientes disponible, es posible que te estés
    preguntando por dónde empezar. En esta guía, aprenderás varios enfoques
    basados en datos para medir con precisión la calidad del servicio y lo que
    cada métrica dice sobre tu empresa.
  </p>
)

const data = {
  start: {
    support: "Guía | Atención al cliente ",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Modelo Servqual.",
      },
      {
        key: 2,
        text: "Encuestas de seguimiento.",
      },
      {
        key: 3,
        text: "Customer Effort Score (CES).",
      },
      {
        key: 4,
        text: "Customer Satisfaction Score (CSAT).",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
